<template lang="pug">
v-card(light, flat)
    v-container(light)
        template(v-if='!sendEmail')
            v-row
                v-col.d-flex
                    v-btn(text, @click='goBack')
                        v-icon(left) arrow_left
                        | Communications
            v-row
                v-col
                    .text-h5 Email Settings
            v-row
                v-col
                    v-checkbox(label='Allow Customization', title='Allow the user to customize the email when sending a manual email', color='brand', v-model='allowCustomization')
        v-row
            v-col
                .text-h5 Variables
                    v-icon(right, small, title='Clicking on a variable will copy text to your clipboard. Paste the copied text into the email to add a variable. The variable will be replaced with real data when the email is sent.') info
        v-row
            v-col.d-flex
                v-chip.mr-2(v-for='variable in emailVariables', :key='variable.id', @click='copyVariable(variable)', :title='variable.description')
                    span {{ variable.name }}
        v-row
            v-col
                span.text-h5 Edit Email
        v-row
            v-col
                v-text-field(label='Subject', outlined, dense, color='brand', v-model='emailSubject', counter='255')
        v-row
            v-col
                VueTrix(v-model='emailBody')
        v-row
            v-col.d-flex
                v-spacer
                v-btn.mr-2(@click='sendTestEmail', :loading='sendingEmail') Send Test Email
                v-btn.white--text.mr-2(color='red', @click='confirmDelete', :loading='deleting', v-if='!sendEmail') Delete
                v-btn.white--text(color='brand', @click='saveTemplate', :loading='saving', v-if='!sendEmail') Save
        v-row
            v-col
                span.text-h5 Preview
        v-row
            v-col
                span(v-html='previewHtml')

    v-dialog(v-model='emailDeleteDialog', max-width='300')
        v-card(light)
            v-card-title Delete Email Template
            v-card-text Are you sure you want to delete this email template?
            v-card-actions
                v-spacer
                v-btn(text, @click='emailDeleteDialog=false') Cancel
                v-btn.white--text(color='red', @click='deleteTemplate') Delete

    v-snackbar(v-model='showAlert', dark, :timeout='3000') {{ snackbarMessage }}
</template>
<script>
import VueTrix from 'vue-trix';
import {debounce} from 'lodash';
export default {
    components: {
        VueTrix
    },
    props: {
        sendEmail: {
            type: Boolean,
            default () {
                return false;
            }
        },
        templateId: {
            type: Number,
            default () {
                return 0;
            }
        }
    },
    data () {
        return {
            accessKey: 'devopscomms',
            emailBody: '',
            emailSubject: '',
            allowCustomization: false,
            templateHtml: '',
            templateFileName: 'default.html',
            previewHtml: '',
            saving: false,
            sendingEmail: false,
            emailVariables: [],
            snackbarMessage: '',
            emailDeleteDialog: false,
            deleting: false
        };
    },
    computed: {
        emailId () {
            return this.sendEmail ? this.templateId : this.$route.query.id;
        },
        showAlert: {
            get () {
                return this.snackbarMessage !== '';
            },
            set (val) {
                if (val === false) {
                    this.snackbarMessage = '';
                }
            },
        }
    },
    methods: {
        async getEmailTemplate () {
            const template = await this.sendCommand({
                action: 'getEmailTemplate',
                parameters: this.emailId
            });
            this.emailBody = template.content;
            this.emailSubject = template.subject;
            this.allowCustomization = template.allowCustomization;
        },
        updatePreview: debounce(function () {
            let html = this.templateHtml;
            html = html.replace('#CONTENT', this.emailBody);
            html = html.replace('#SUBJECT', this.emailSubject);
            const date = new Date();
            for (const emailVar of this.emailVariables) {
                const textToReplace = `{{${emailVar.replacementText}}}`;
                while (html.includes(textToReplace)) {
                    let replacementText = '';
                    switch (emailVar.replacementText) {
                    case 'firstName':
                        replacementText = 'Jane';
                        break;
                    case 'lastName':
                        replacementText = 'Doe';
                        break;
                    case 'shortDate':
                        replacementText = date.toLocaleDateString();
                        break;
                    case 'longDate':
                        replacementText = date.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"});
                        break;
                    case 'latestInfoScanVersion':
                        replacementText = '1.2.3';
                        break;
                    case 'currentInfoScanVersion':
                        replacementText = '1.0.0';
                        break;
                    }
                    html = html.replace(textToReplace, replacementText);
                }
            }
            this.previewHtml = html;
            this.emailEdited();
        }, 500),
        goBack () {
            this.$router.push('/devops/communications');
        },
        async saveTemplate () {
            this.saving = true;
            const result = await this.sendCommand({
                action: 'saveEmailTemplate',
                parameters: {
                    content: this.emailBody,
                    subject: this.emailSubject,
                    allowCustomization: this.allowCustomization,
                    id: this.emailId
                }
            });
            if (result) {
                if (result.err) {
                    this.snackbarMessage = result.err;
                } else {
                    this.snackbarMessage = 'Saved succesfully!';
                }
            } else {
                this.snackbarMessage = 'An unknown error occured!';
            }
            this.saving = false;
        },
        async sendTestEmail () {
            this.sendingEmail = true;
            const result = await this.sendCommand({
                action: 'sendManualEmail',
                parameters: {
                    id: this.emailId,
                    email: this.$store.state.dorsettUser.email,
                    subject: this.emailSubject,
                    content: this.emailBody
                }
            });
            if (result) {
                if (result.err) {
                    this.snackbarMessage = 'There was an error sending the email!';
                }
                else {
                    this.snackbarMessage = 'Sent!';
                }
            }
            this.sendingEmail = false;
        },
        getEmailVariables () {
            this.socketEmit('getEmailVariables', {}, (data) => {
                this.emailVariables = data;
            });
        },
        copyVariable (variable) {
            navigator.clipboard.writeText(`{{${variable.replacementText}}}`);
        },
        emailEdited () {
            this.$emit('emailEdited', {
                content: this.emailBody,
                subject: this.emailSubject
            });
        },
        confirmDelete () {
            this.emailDeleteDialog = true;
        },
        deleteTemplate () {
            this.deleting = true;
            this.$nextTick(async () => {
                const result = await this.sendCommand({
                    action: 'deleteEmailTemplate',
                    parameters: {
                        id: this.emailId
                    }
                });
                if (result) {
                    if (result.err) {
                        this.snackbarMessage = result.err;
                    } else {
                        this.snackbarMessage = 'Deleted email template!';
                    }
                } else {
                    this.snackbarMessage = 'An unknown error occured!';
                }
                this.deleting = false;
                this.$router.push('/devops/communications');
            });
        }
    },
    watch: {
        emailBody: {
            immediate: true,
            handler () {
                this.updatePreview();
            }
        },
        emailSubject: {
            immediate: true,
            handler () {
                this.updatePreview();
            }
        },
        templateFileName: {
            immediate: true,
            handler (fileName) {
                this.socketEmit('getEmailContainer', {fileName}, (data) => {
                    this.templateHtml = data;
                });
            }
        },
        emailId: {
            immediate: true,
            handler () {
                this.getEmailTemplate();
            }
        }
    },
    mounted () {
        window.emaileditorvm = this;
        this.getEmailVariables();
    }
};
</script>
